<template>
  <ion-page id="page-content">
    <ion-content :fullscreen="true">
      <topbar
        :bigtitle="true"
        :class="{ 'letter-ios-14': $store.state.deviceType == 1 }"
        title="Contact Us"
        :showBack="true"
      ></topbar>
      <div class="pc-box big">
        <div class="pc-min-content">
          <div class="content">
            <div class="title-PC" v-if="$store.state.isPC">Contact Us</div>
            <div class="form-item">
              <label>Name</label>
              <div class="input-box">
                <input type="text" v-model="name" placeholder="Name" />
              </div>
            </div>
            <div class="form-item">
              <label>Email</label>
              <div class="input-box">
                <input
                  type="text"
                  v-model="email"
                  @input="email = $event.target.value.trim().toLowerCase()"
                  placeholder="Email"
                />
              </div>
            </div>
            <div class="form-item">
              <label>Message</label>
              <div class="textarea-box">
                <textarea v-model="msg" rows="10" cols="33" />
              </div>
            </div>
            <ion-button
              expand="block"
              :disabled="!name || !email || !msg"
              @click="sendEvent"
              >Send Message</ion-button
            >
            <div class="height250"></div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonButton } from "@ionic/vue";
import topbar from "@/components/topbar.vue";
import config from "../config";
export default {
  name: "contactUs",
  mixins: [config.globalMixin],
  components: { IonContent, IonPage, topbar, IonButton },
  data() {
    return {
      name: "",
      email: "",
      msg: "",
    };
  },
  methods: {
    async sendEvent() {
      const objdata = this.getAuthObj();
      objdata.userName = this.name;
      objdata.email = this.email.trim().toLowerCase();
      objdata.message = this.msg;
      objdata.apiUrl = this.apiConfig.sendIquiryEmail;
      const data = await this.$store.dispatch("apiEvent", objdata);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      this.successToast("Success!");
      this.sendGAEvent("Click", "ContactUs", "Send Message");
      setTimeout(() => {
        if (this.backevent) {
          this.backevent();
          return;
        }
        this.$router.go(-1);
      }, 1500);
    },
  },
  ionViewDidEnter() {
    if (this.$store.state.form.token) {
      this.name =
        this.$store.state.form.userInfo.firstName +
        " " +
        this.$store.state.form.userInfo.lastName;
      this.email = this.$store.state.form.userInfo.email.trim().toLowerCase();
    }
    this.$store.state.exitApp = false;

    this.sendGAPageView("Contact Us");
  },
};
</script>
